import Layout from 'components/layout';
import SupportedLanguages from 'components/supportedLang';
import React from 'react';
import Seo from 'components/seo';

export default function index() {
 return (
     <Layout>
         <Seo title="Supported Languages" />
         <SupportedLanguages/>
     </Layout>
 )
}
